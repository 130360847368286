.glitch {
    /* width: 900px; */
    height: 700px;
    margin-left: 10px;
    margin-top: -600px;
  }
  


  /* HYDRO VARIOUS PIPE MATERIALS */
  .glitch-static-hvpm {
    background: url('../assets/images/hydro/hwj7/hvpm1.png') no-repeat center center;
    background-size: contain;
  }

  .glitch-active-hvpm {
    animation: glitch-hvpm 1.5s infinite steps(1);
  }
  
  @keyframes glitch-hvpm {
    10%, 100% {
      background: url('../assets/images/hydro/hwj7/hvpm2.png') no-repeat center center;
      background-size: contain;
    }
    25% {
      background: url('../assets/images/hydro/hwj7/hvpm3.png') no-repeat center center;
      background-size: contain;
      opacity: 1;
    }
    50% {
      background: url('../assets/images/hydro/hwj7/hvpm4.png') no-repeat center center;
      background-size: contain;
      opacity: 1;
    }
    99% {
      background: url('../assets/images/hydro/hwj7/hvpm5.png') no-repeat center center;
      background-size: contain;
      opacity: 1;
    }
  }






  