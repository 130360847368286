.glitch {
    /* width: 900px; */
    height: 700px;
    margin-left: 10px;
    margin-top: -600px;
  }
  


  /* HYDRO ROADS */
  .glitch-static-hr4 {
    background: url('../assets/images/hydro/hwj4/hr41.png') no-repeat center center;
    background-size: contain;
  }

  .glitch-active-hr4 {
    animation: glitch-hr4 1.5s infinite steps(1);
  }
  
  @keyframes glitch-hr4 {
    10%, 100% {
      background: url('../assets/images/hydro/hwj4/hr42.png') no-repeat center center;
      background-size: contain;
    }
    25% {
      background: url('../assets/images/hydro/hwj4/hr43.png') no-repeat center center;
      background-size: contain;
      opacity: 1;
    }
    50% {
      background: url('../assets/images/hydro/hwj4/hr44.png') no-repeat center center;
      background-size: contain;
      opacity: 1;
    }
    99% {
      background: url('../assets/images/hydro/hwj4/hr45.png') no-repeat center center;
      background-size: contain;
      opacity: 1;
    }
  }






  /* HYDRO RUNWAYS */
  .glitch-static-hrw4 {
    background: url('../assets/images/hydro/hwj4/hrw41.png') no-repeat center center;
    background-size: contain;
  }

  .glitch-active-hrw4 {
    animation: glitch-hrw4 1.5s infinite steps(1);
  }
  
  @keyframes glitch-hrw4 {
    10%, 100% {
      background: url('../assets/images/hydro/hwj4/hrw42.png') no-repeat center center;
      background-size: contain;
    }
    25% {
      background: url('../assets/images/hydro/hwj4/hrw43.png') no-repeat center center;
      background-size: contain;
      opacity: 1;
    }
    50% {
      background: url('../assets/images/hydro/hwj4/hrw44.png') no-repeat center center;
      background-size: contain;
      opacity: 1;
    }
    99% {
      background: url('../assets/images/hydro/hwj4/hrw45.png') no-repeat center center;
      background-size: contain;
      opacity: 1;
    }
  }
