.glitch {
    /* width: 900px; */
    height: 700px;
    margin-left: 10px;
    margin-top: -700px;
    margin-bottom: 90px;
    /* position: relative; */
    z-index: -2;
  }
  


  /* HYDRO TUBE HEAT EXCHANGERS */
  .glitch-static-ppp6 {
    background: url('../assets/images/pressure/prst6/ppp61.png') no-repeat center center;
    background-size: contain;
  }

  .glitch-active-ppp6 {
    animation: glitch-ppp6 1.5s infinite steps(1);
  }
  
  @keyframes glitch-ppp6 {
    10%, 100% {
      background: url('../assets/images/pressure/prst6/ppp62.png') no-repeat center center;
      background-size: contain;
    }
    25% {
      background: url('../assets/images/pressure/prst6/ppp63.png') no-repeat center center;
      background-size: contain;
      opacity: 1;
    }
    50% {
      background: url('../assets/images/pressure/prst6/ppp64.png') no-repeat center center;
      background-size: contain;
      opacity: 1;
    }
    99% {
      background: url('../assets/images/pressure/prst6/ppp65.png') no-repeat center center;
      background-size: contain;
      opacity: 1;
    }
  }






  