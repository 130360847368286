.glitch {
    /* width: 900px; */
    height: 700px;
    margin-left: 10px;
    margin-top: -600px;
    /* position: relative; */
    z-index: -2;
  }
  


  /* HYDRO TUBE HEAT EXCHANGERS */
  .glitch-static-pop1 {
    background: url('../assets/images/pressure/prst1/pop11.png') no-repeat center center;
    background-size: contain;
  }

  .glitch-active-pop1 {
    animation: glitch-pop1 1.5s infinite steps(1);
  }
  
  @keyframes glitch-pop1 {
    10%, 100% {
      background: url('../assets/images/pressure/prst1/pop12.png') no-repeat center center;
      background-size: contain;
    }
    25% {
      background: url('../assets/images/pressure/prst1/pop13.png') no-repeat center center;
      background-size: contain;
      opacity: 1;
    }
    50% {
      background: url('../assets/images/pressure/prst1/pop14.png') no-repeat center center;
      background-size: contain;
      opacity: 1;
    }
    99% {
      background: url('../assets/images/pressure/prst1/pop15.png') no-repeat center center;
      background-size: contain;
      opacity: 1;
    }
  }






  