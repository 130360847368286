.glitch {
    /* width: 900px; */
    height: 700px;
    margin-left: 10px;
    margin-top: -600px;
  }
  


  /* HYDRO STORAGE TANKS */
  .glitch-static-hst3 {
    background: url('../assets/images/hydro/hwj3/hst31.png') no-repeat center center;
    background-size: contain;
  }

  .glitch-active-hst3 {
    animation: glitch-hst3 1.5s infinite steps(1);
  }
  
  @keyframes glitch-hst3 {
    10%, 100% {
      background: url('../assets/images/hydro/hwj3/hst32.png') no-repeat center center;
      background-size: contain;
    }
    25% {
      background: url('../assets/images/hydro/hwj3/hst33.png') no-repeat center center;
      background-size: contain;
      opacity: 1;
    }
    50% {
      background: url('../assets/images/hydro/hwj3/hst34.png') no-repeat center center;
      background-size: contain;
      opacity: 1;
    }
    99% {
      background: url('../assets/images/hydro/hwj3/hst35.png') no-repeat center center;
      background-size: contain;
      opacity: 1;
    }
  }






  /* HYDRO CARGO OIL TANKS */
  .glitch-static-hcot3 {
    background: url('../assets/images/hydro/hwj3/hcot31.png') no-repeat center center;
    background-size: contain;
  }

  .glitch-active-hcot3 {
    animation: glitch-hcot3 1.5s infinite steps(1);
  }
  
  @keyframes glitch-hcot3 {
    10%, 100% {
      background: url('../assets/images/hydro/hwj3/hcot32.png') no-repeat center center;
      background-size: contain;
    }
    25% {
      background: url('../assets/images/hydro/hwj3/hcot33.png') no-repeat center center;
      background-size: contain;
      opacity: 1;
    }
    50% {
      background: url('../assets/images/hydro/hwj3/hcot34.png') no-repeat center center;
      background-size: contain;
      opacity: 1;
    }
    99% {
      background: url('../assets/images/hydro/hwj3/hcot35.png') no-repeat center center;
      background-size: contain;
      opacity: 1;
    }
  }
