.glitch {
    /* width: 900px; */
    height: 700px;
    margin-left: 10px;
    margin-top: -600px;
  }
  


  /* HYDRO STORAGE TANKS  */
  .glitch-static-hst9 {
    background: url('../assets/images/hydro/hwj9/hst91.png') no-repeat center center;
    background-size: contain;
  }

  .glitch-active-hst9 {
    animation: glitch-hst9 1.5s infinite steps(1);
  }
  
  @keyframes glitch-hst9 {
    10%, 100% {
      background: url('../assets/images/hydro/hwj9/hst92.png') no-repeat center center;
      background-size: contain;
    }
    25% {
      background: url('../assets/images/hydro/hwj9/hst93.png') no-repeat center center;
      background-size: contain;
      opacity: 1;
    }
    50% {
      background: url('../assets/images/hydro/hwj9/hst94.png') no-repeat center center;
      background-size: contain;
      opacity: 1;
    }
    99% {
      background: url('../assets/images/hydro/hwj9/hst95.png') no-repeat center center;
      background-size: contain;
      opacity: 1;
    }
  }






  