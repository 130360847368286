body {
  /* height: 100vh; */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 2%,
    rgb(3, 4, 5) 20%,
    rgba(35, 49, 58, 1) 80%,
    rgba(0, 0, 0, 1) 98%
  );
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.view {
  height: 100vh !important;
  overflow-x: hidden;
  overflow-y: hidden;
  /* z-index: -20; */
}

.learn-more-btn {
  display: inline-block;
  margin-left: 0px;
  margin-top: 20px;
}

.top-mid {
  top: 45%;
}

.dots-container {
  opacity: 0;
}

.dot-navigate {
  transition: all 0.2s ease-out;
}

.dot-navigate-active {
  background-color: red;
}

.dot-active {
  margin-left: 8px;
}

.dot-navigate-active-green {
  background-color: #22c55e;
  transition: all 0.2s ease-out;
}
.dot-navigate-active-green:hover {
  background-color: #22c55e;
  transform: scale(1.5);
  cursor: pointer;
}

.dot-navigate-active-red {
  background-color: #ef4444;
  transition: all 0.2s ease-out;
}

.dot-navigate-active-red:hover {
  background-color: #ef4444;
  transform: scale(1.5);
  cursor: pointer;
}

.dot-navigate-active-white {
  background-color: #fffdd0;
  transition: all 0.2s ease-out;
}

.dot-navigate-active-purple {
  background-color: #8b5cf6;
  transition: all 0.2s ease-out;
}

.dot-navigate-active-blue {
  background-color: #06b6d4;
  transition: all 0.2s ease-out;
}

.dot-navigate:hover {
  transform: scale(1.5);
  background-color: white;
  cursor: pointer;
}

.go-back-btn {
  border-bottom: 0.5px dotted red;
}

.go-back-btn-green {
  border-bottom: 0.5px dotted green;
}

.go-back-btn-green:hover {
  cursor: pointer;
}
.go-back-btn-green {
  border-bottom: 0.5px dotted green;
}

.go-back-btn-green:hover {
  cursor: pointer;
}
.go-back-btn-white {
  border-bottom: 0.5px dotted white;
}

.go-back-btn-white:hover {
  cursor: pointer;
}

.go-back-btn-blue {
  border-bottom: 0.5px dotted cyan;
}

.go-back-btn-blue:hover {
  cursor: pointer;
}

.go-back-btn-purple {
  border-bottom: 0.5px dotted #c4b5fd;
}

.go-back-btn-purple:hover {
  cursor: pointer;
}

.go-back-btn:hover {
  cursor: pointer;
}

.contact-slide {
  background: linear-gradient(rgba(194, 194, 214, 0.1), transparent);
  height: 50%;
}

.header-text {
  font-size: 4.5rem;
  font-family: rajdhani;
  font-weight: 600;
  font-style: normal;
  /* text-transform: uppercase; */
}

.text-22px {
  font-size: 22px;
  font-weight: 200;
}

.rotate {
  transform: rotate(0.5turn);
}

.loading-spinner {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.click-to-start-btn {
  cursor: pointer;
  opacity: 1;
  top: 70%;
  left: 44.8%;
  color: rgba(250, 250, 250, 0.9);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border: 1px solid rgba(255, 255, 255, 0.5);
  width: 10vw;
  height: 5vh;
  background-color: rgba(230, 230, 230, 0.2);
  transition: box-shadow 0.3s ease-in;
}

.click-to-start-btn:hover {
  box-shadow: 0px 0px 5px #ddd;
}

.bar-graph {
  z-index: 10;
  bottom: 5rem;
  left: 4.5rem;
}

.circles {
  bottom: 11.45rem;
  left: 8.05rem;
}

.circles2 {
  bottom: 10.2rem;
  left: 6.8rem;
}

.line-graph {
  bottom: 4.5rem;
  left: 12.5rem;
}

.line-effect {
  padding-left: 5rem;
  padding-right: 5rem;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.coordinates-effect {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  top: 5rem;
  right: 6rem;

  background-color: transparent;
  font-family: rajdhani;
  color: #ffffff33;
  font-size: 0.8rem;
}

.overlay-for-world {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0);
  pointer-events: none;
}

.dezu-header {
  color: #ffffff33;
  font-size: 0.8rem;
  top: 4.8rem;
  left: 6rem;
}
