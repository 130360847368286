.glitch-ct1 {
    /* width: 900px; */
    height: 700px;
    margin-left: 0px;
    margin-top: -620px;
    margin-bottom: 50px;
    /* position: relative; */
    z-index: -2;
  }
  


  /* CLEANING | OFFSHORE APPLICATION */
  .glitch-static-eoa3 {
    background: url('../assets/images/environmental/envr3/eoa31.png') no-repeat center center;
    background-size: contain;
  }

  .glitch-active-eoa3 {
    animation: glitch-eoa3 1.5s infinite steps(1);
  }
  
  @keyframes glitch-eoa3 {
    10%, 100% {
      background: url('../assets/images/environmental/envr3/eoa32.png') no-repeat center center;
      background-size: contain;
    }
    25% {
      background: url('../assets/images/environmental/envr3/eoa33.png') no-repeat center center;
      background-size: contain;
      opacity: 1;
    }
    50% {
      background: url('../assets/images/environmental/envr3/eoa34.png') no-repeat center center;
      background-size: contain;
      opacity: 1;
    }
    99% {
      background: url('../assets/images/environmental/envr3/eoa35.png') no-repeat center center;
      background-size: contain;
      opacity: 1;
    }
  }





   